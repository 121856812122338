<script>
  /**
   * if you want credentials to appear, supply username and password props
   */
  import Button from '../components/Button.svelte';
  export let title;
  export let description;
  export let imgSrc;
  export let homepageLink;
  export let adminLink;
  export let className = '';
  export let username;
  export let password;
  import { _ } from 'svelte-i18n';
</script>

<style>
  .demo-template {
    width: 540px;
  }
  .img {
    width: 100%;
    height: 270px;
    object-fit: cover;
    margin-bottom: 23px;
  }
  .title {
    font-size: 30px;
    font-weight: 600;
  }
  .description {
    font-size: 18px;
    margin-bottom: 30px;
    line-height: 28px;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
  }
  .button-wrapper {
    margin-right: 15px;
    margin-bottom: 10px;
    margin-left: 0;
    display: block;
  }

  .credentials {
    margin-right: 15px;
    margin-bottom: 10px;
    margin-left: 0;
    display: block;
  }
  @media (max-width: 600px) {
    .demo-template {
      width: 100%;
      margin-bottom: 20px;
    }
  }
</style>

<div class={`demo-template ${className}`}>
  <img class="img" src={imgSrc} alt={$_(title)} loading="lazy" />
  <div class="title">{$_(title)}</div>
  <div class="description">{$_(description)}</div>
  {#if username && password}
    <div class="credentials">
      <div class="item">{$_('demo.username')}{username}</div>
      <div class="item">{$_('demo.password')}{password}</div>
    </div>
  {/if}
  <div class="buttons">
    <a
      class="button-wrapper"
      href={homepageLink}
      target="_blank"
      rel="noopener">
      <Button uppercase>{$_('demos.demoForCustomer')}</Button>
    </a>
    <a class="button-wrapper" href={adminLink} target="_blank" rel="noopener">
      <Button uppercase>{$_('demos.demoForAdmin')}</Button>
    </a>
  </div>
</div>
